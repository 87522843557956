@import '../src/styles/variables/spacing';
@import '../src/styles/variables/colors';

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: spacing(4);
    border-radius: 4px;
    background-color: $color-background-polar;
    width: 100%;

    .deleteButton {
        color: $color-grey-60;
        margin: spacing(1);
    }

    .text {
        padding: spacing(3) 0;
    }
}
