@import 'styles/variables/colors';
@import 'styles/variables/spacing';

.title {
    position: relative;
    font-family: 'Source Sans Pro';
    font-size: 32px;
    font-weight: 600;
    color: #232326;
}

.h3 {
    font-size: 28px;
}

.h4 {
    font-size: 20px;
}

.h5 {
    font-size: 18px;
}

.h6 {
    font-size: 16px;
}

.h7 {
    font-size: 14px;
}

.h8 {
    font-size: 12px;
}
