.modal {
    // border: 1px solid #ccc;
    background: #fff;
    outline: none;
    border-radius: 8px;
    max-width: 586px;
    width: 100%;
    max-height: 80vh;
    overflow: auto;
    margin-left: 20px;
    margin-right: 20px;

    &.is-big {
        max-width: 1198px;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: rgba(35, 35, 38, 0.48);
    display: flex;
    justify-content: center;
    align-items: center;
}
