@import 'styles/variables/colors';
@import 'styles/variables/spacing';

.container {
    display: flex;
    align-items: center;
    width: max-content;
    justify-content: center;
    border-radius: 8px;
    background-color: $color-grey-5;
}
