@import 'styles/variables/colors';
@import 'styles/variables/spacing';
@import 'styles/variables/media-query';

.container {
    max-width: $input-width;

    .secureContainer {
        .secureTitle {
            font-size: 12px;
            font-weight: 600;
        }

        .secureParagraph {
            margin: spacing(1) 0;
            font-size: 12px;
            color: $color-grey-80;
        }

        .secureBarContainer {
            width: 100%;
            height: 4px;
            background-color: $color-grey-10;
            .secureBar {
                height: 4px;
            }
        }
    }

    &.grow {
        max-width: 100%;
    }

    .default {
        .secureTitle {
            color: $color-grey-80;
        }
    }

    .weak {
        .secureTitle {
            color: $color-ui-red;
        }

        .secureBar {
            background-color: $color-ui-red;
        }
    }

    .mediocre {
        .secureTitle {
            color: $color-ui-orange;
        }
        .secureBar {
            background-color: $color-ui-orange;
        }
    }

    .strong {
        .secureTitle {
            color: $color-ui-green;
        }

        .secureBar {
            background-color: $color-ui-green;
        }
    }
}
