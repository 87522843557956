@import 'styles/variables/animations';
@import 'styles/variables/spacing';
@import 'styles/variables/colors';

.icon {
    svg {
        color: inherit;
        font-size: 12px;
        pointer-events: none;
        transform-origin: 50% 50%;
        transition: transform $animation-appear;
    }
}

.iconStyleEnabled {
    color: $color-ui-green;
}

.iconStyleDisabled {
    color: $color-grey-20;
}
