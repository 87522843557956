@import 'styles/variables/spacing';
@import 'styles/variables/colors';

.tableContainer {
    position: relative;
    display: grid;
    grid-template-columns: 30% auto;
    width: 100%;
    min-height: 76px;
    padding: spacing(6);

    .titleContainer {
        text-transform: uppercase;
        color: $color-grey-80;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.4px;
        margin-bottom: spacing(2.5);
    }

    .rightComponentContainer {
        display: grid;
        grid-template-columns: repeat(1, 118px 1fr 1fr);
    }
}
