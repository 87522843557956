.container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &.grow {
        height: 100%;
        width: 100%;
    }
}
