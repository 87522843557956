@import 'styles/variables/colors';
@import 'styles/variables/spacing';

.container {
    padding: spacing(2) spacing(4);
    background-color: $color-ui-red-light;
    border-radius: 8px;
    border: 1px solid $color-ui-red;
    color: $color-white;
}

.title {
    font-size: 20px;
    font-weight: 600;
    color: $color-white;
    margin-bottom: spacing(2);
}

.message {
    color: $color-white;
}
