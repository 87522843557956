@import 'styles/variables/colors';
@import 'styles/variables/spacing';

.container {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    min-width: 1150px;
}

.navigationContainer {
    height: 100%;
    background-color: $color-white;
}

.contentContainer {
    overflow-y: scroll;
    position: relative;
    padding: spacing(16.5) spacing(18) spacing(8) spacing(18);
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}
