@import './src/styles/variables/spacing';
@import './src/styles/variables/colors';

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: spacing(4);
    border-radius: 4px;
    border: solid 1px $color-grey-20;
    background-color: $color-white;
    max-width: 407px;

    & > * + * {
        margin-top: spacing(2);
    }
}
