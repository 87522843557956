@import 'styles/variables/spacing';
@import 'styles/variables/colors';

.link {
    font-weight: 600;
    color: $color-grey-99;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: $color-bisc-orange;
    }
}
