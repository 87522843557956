@import 'styles/variables/colors';
@import 'styles/variables/spacing';
@import 'styles/variables/utils';
@import 'styles/variables/animations';

.button {
    position: relative;
    display: inline-block;
    padding: spacing(3) spacing(5);
    border-radius: 8px;
    border: none;

    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;

    user-select: none;
    touch-action: manipulation;
    cursor: pointer;

    transition-property: background-color color border text-decoration;
    transition-duration: $animation-default;
    transition-timing-function: $animation-appear-bezier;

    padding: 4px;
    background-color: $color-white;
    border-radius: 50%;
    color: $color-grey-60;

    &:hover {
        color: $color-bisc-orange;
    }

    .inner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:focus {
        outline: none;
    }
}
