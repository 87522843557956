.periodFieldWithoutSelect {
    width: 100%;
}
.periodFieldWithSelect {
    width: 128px;
}
.taalhuisSelectWrapper {
    width: 50%;
}
.providerSelectWrapper {
    width: 50%;
}
.periodWrapper {
    width: 100%;
}
