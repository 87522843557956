@import 'styles/variables/spacing';
@import 'styles/variables/animations';
@import 'styles/variables/media-query';

.container {
    position: fixed;
    bottom: 130px; // make room for potential bottom action-bar
    right: 0;
    display: flex;
    flex-direction: column-reverse;
    padding: spacing(1);
    z-index: 9999;
    pointer-events: none;
    max-width: 1016px;
    margin: 0 auto;

    @include media-query(tablet) {
        padding-right: spacing(8);
    }
}

.enter {
    opacity: 0;
    transform: translateX(75%);
}

.enterActive {
    opacity: 1;
    transform: translateX(0%);
}

.exit {
    opacity: 1;
    transform: translateX(0%);
}

.exitActive {
    opacity: 0;
    transform: translateX(75%);
}
