// Brand colors
$color-bisc-orange: #ff5a26;
$color-bisc-ice: #92bed8;

// Grey colors
$color-white: #fff;
$color-grey-5: #f2f2f7;
$color-grey-10: #f5f5fa;
$color-grey-20: #d9d9de;
$color-grey-60: #b2b2ba;
$color-grey-70: #aeaeae;
$color-grey-75: #87878a;
$color-grey-80: #87878f;
$color-grey-95: #2f2f33;
$color-grey-99: #232326;
$color-black: #000;
// UI colors
$color-ui-green-light: #f5fcf7;
$color-ui-green: #41c15c;
$color-ui-green-dark: #0f7725;
$color-ui-green-transparent: rgba(65, 193, 92, 0.1);
$color-ui-orange: #ff8f26;

$color-ui-red: #cb052d;
$color-ui-red-light: #e10000;

// Background colors
$color-background-salmon: #ffba94;

$color-background-polar: #d9f1ff;
$color-background-polar-dark: #c7e7fa;
$color-background-polar-darker: #bee1f6;

$color-background-orange: $color-bisc-orange;
$color-background-orange-dark: #f24c18;
$color-background-orange-darker: #f2430c;

$color-background-red-light: #ffeee9;
$color-background-red: #cd2500;

$color-background-yellow-light: #fff7ec;
$color-background-yellow: #ffb342;
$color-background-yellow-dark: #885100;

$color-background-blue: #004974;
$color-background-dark-blue: #1922c9;
$color-background-light-blue: #d9f1ff;

$color-background-purple-light: #efe7ec;
$color-background-purple: #681249;

$color-background-green: #0f7725;
