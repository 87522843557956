@import 'styles/variables/colors';
@import 'styles/variables/spacing';
@import 'styles/variables/media-query';

.container {
    display: flex;
    flex-direction: column;
    padding: spacing(4) spacing(6) spacing(5) spacing(6);
    max-width: 403px;
    border-radius: 4px;
    background-color: $color-grey-5;

    input {
        background-color: $color-white;
    }
}
