@import 'styles/variables/colors';

.container {
    display: flex;
    flex-direction: row;
}

.breadcrumb {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;

    user-select: none;
    touch-action: manipulation;
    cursor: pointer;

    &:hover,
    :active {
        .label {
            color: $color-bisc-orange;
        }
    }
}

.label {
    font-size: 12px;
    line-height: 2.17;
    color: $color-grey-80;
}

.icon {
    color: $color-grey-80;
    margin-left: 8px;
    margin-right: 4px;
}
