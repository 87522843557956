@import 'styles/variables/colors';
@import 'styles/variables/spacing';
@import 'styles/variables/media-query';

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: $input-width;

    &.grow {
        max-width: 100%;
    }
}

.inputField {
    // color: $color-white;
    // background-color: $color-grey-99;
    border: solid 1px $color-grey-20;
    border-radius: 4px;
    padding: 10px 8px;
    font-size: 14px;
    line-height: 14px;
    outline: none;

    &::placeholder {
        color: $color-grey-70;
    }

    &:focus {
        border-color: $color-background-polar;
    }

    .hasErrorMessage & {
        border-color: $color-ui-red;
    }

    &:disabled {
        border-color: $color-grey-20;
        background-color: $color-grey-5;
        color: $color-grey-70;
    }
}

.errorMessage {
    margin-top: spacing(2);
    font-size: 14px;
    color: $color-ui-red;
}
