.iconToggle {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';
    @import 'styles/variables/spacing';

    cursor: pointer;
    color: $color-grey-60;
    padding: spacing(1);
    border-radius: 24px;

    transition: background-color color;
    transition-duration: $animation-default;
    transition-timing-function: $animation-appear-bezier;

    &:hover,
    &.toggled {
        color: $color-white;
        background-color: $color-bisc-orange;
    }

    &.toggled:hover {
        background-color: $color-background-orange-darker;
    }
}
