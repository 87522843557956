@import-normalize;
@import 'styles/variables/fonts';
@import 'styles/variables/media-query';
@import 'styles/variables/colors';

html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

html,
body {
    min-height: 100vh;
    padding: 0;
}

/*
 * Give element block that are not block by default.
 * This makes sure the parent div is the exact size of the element.
 */
label,
img,
audio,
video,
canvas,
meter,
progress,
svg,
iframe {
    display: block;
}

body {
    margin: 0;
    font-family: $font-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $color-white;
    color: $color-grey-99;
    line-height: 1.4;
    min-width: 1150px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    // components should not have default margins or paddings. These should be style in the components.
    margin: 0;
    padding: 0;
    font-family: inherit;
    line-height: inherit;
    color: inherit;
    box-sizing: inherit;
    word-break: break-word;
}

*:before,
*:after {
    box-sizing: inherit;
}
