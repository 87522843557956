@import 'styles/variables/colors';
@import 'styles/variables/spacing';
@import 'styles/variables/media-query';

.container {
    display: flex;
    flex-direction: column;
    max-width: $input-width;

    &.grow {
        max-width: 100%;
    }
}
.eye {
    cursor: pointer;
    color: $color-grey-60;
    position: absolute;
    margin-top: spacing(3);
    right: spacing(2);
}

.arrow.disabledEye {
    cursor: no-drop;
    color: $color-grey-80;
}
