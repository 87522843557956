@import 'styles/variables/colors';
@import 'styles/variables/spacing';

.container {
    max-width: 1200px;
    display: grid;
    grid-template-rows: repeat(4);
    grid-template-columns: repeat(2, 1fr);
    row-gap: spacing(10);
    column-gap: spacing(10, -2);
}
