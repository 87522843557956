@import 'styles/variables/colors';
@import 'styles/variables/spacing';

.hr {
    margin: spacing(10) 0px;
    height: 4px;
    border-color: $color-grey-5;
    border-radius: 2px;
    color: $color-grey-5;
    background-color: $color-grey-5;
    border: none;

    &.is-spacing-disabled {
        margin: 0;
    }

    &.dark {
        color: $color-grey-20;
        background-color: $color-grey-20;
        border-color: $color-grey-20;
    }

    &.thin {
        height: 1px;
    }
}
